@font-face {
  font-family: "Avenir Next Semi Bold";
  src: url("/assets/fonts/AvenirNext-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: "Avenir Next Regular";
  src: url("/assets/fonts/AvenirNext-Regular.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: "Avenir Next Medium";
  src: url("/assets/fonts/AvenirNext-Medium.ttf") format('truetype');
}

@font-face {
  font-family: "Avenir Next Bold";
  src: url("/assets/fonts/AvenirNext-Bold.ttf") format('truetype');
  font-weight: 700;
}

:root {
  --ion-font-family: 'Avenir Next Medium';
}

h1, h2 {
  font-family: "Avenir Next Bold";
}

h3, h4, h5, h6 {
  font-family: "Avenir Next Semi Bold";
}

p {
  font-family: "Avenir Next Regular";
}

@media (max-width: 540px) {
  html {
    font-size: 11px;
  }
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}

.heartbeat
{  
  animation: heartbeat 1s infinite;
}

.native-input.sc-ion-input-md {
  margin-top: 0;
}