$orange: #fb9736;
$border-color: #a9a8a7;
$background: #fcf5ed;
$footer-grey: #f1e7f2;
$theme-green: #b6cc09;
$theme-purple: #725acc;
$card-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
$spacing: clamp(1rem, 5vw, 4rem);

.slides-md {
  --bullet-background-active: #b6cc09;
}

@mixin spacing($prop) {
  #{$prop}: $spacing;
};
