.subscribe-page {
    background: #725acc;
}
.subscribe-text {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    h2 {
        color: #ffffff;
        font-size: 64px;
        font-weight: bold;
    }
    .subscribe-caption {
        width: 55%;
        margin-top: 20px;
    }
}
.subscribe-form {
    width: 40%;
}
.subscribe-form-body {
    .form-item {
        margin: 20px 0;
    }
}