.banner {
  background-image: url("/images/covidtab_banner.png");
  padding: 3em 0;
  background-size: cover;
}

.pointCount {
  height: 8rem;
  margin-right: 1rem;
}

.samIcon {
  margin: 0 4px;
}

.bannerTitle h1 {
  font-size: 3.6em;
}

.line2 {
  margin-left: 2.6em;
}

.preTitle {
  margin-left: 5.5em;
  font-size: 1.5em;
}

.para {
  font-size: 1.8em;
  padding: 2em 0;
}