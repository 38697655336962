@import "./app-theme.scss";

.bg-orange {
  background-color: $orange;
}

.bg-green {
  background-color: $theme-green;
}

.bg-white {
  background-color: var(--ion-color-primary-contrast-rgb);
}

.color-green {
  color: $theme-green;
}

.color-dark {
  color: #000;
}

.color-light {
  color: var(--ion-color-primary-contrast-rgb);;
}

.bg-purple {
  background-color: $theme-purple;
}

.color-purple {
  color: $theme-purple;
}

.padding-lg {
  padding: 2rem;
}

.padding-std {
  padding: 1rem;
}

.u-padding-horizontal {
  @include spacing(padding-top);
  @include spacing(padding-bottom);
}

.u-no-margin {
  margin: 0;
}

.u-margin-bottom_1 {
  margin-bottom: 1em;
}

.u-margin-top_1 {
  margin-top: 1em;
}

.u-font-bold {
  font-weight: 900;
}

.u-justify-center {
  justify-content: space-between;
}

.u-margin-auto {
  margin: auto;
}

.u-line-height-para {
  line-height: 1.6;
}

.u-flex {
  display: flex;
}

.text-link {
  color: inherit;
  text-decoration: none;
}

.u-pos-rel {
  position: relative;
}

.u-title_lg {
  font-size: 3em;
}

.u-title {
  font-size: 2.2em;
}

.u-full-width {
  width: 100%;
}

.u-border {
  border: 1px solid #000;
}

.u-font-size_1 {
  font-size: 1em;
}

.u-font-regular {
  font-family: "Avenir Next Regular";
}

.u-font-semi-bold {
  font-family: "Avenir Next Semi Bold";
}

.text-transform-inherit {
  text-transform: inherit;
}

.u-height-inherit {
  height: inherit;
}

.u-card-shadow:hover {
  box-shadow: $card-shadow;
}

.u-text-inherit {
  text-decoration: inherit;
  color: inherit;
}

.u-pos-rel {
  position: relative;
}

.c-hyphen {
  margin-left: 2em;
  &::before {
    content: "";
    position: absolute;
    left: -1.8em;
    height: 0.2em;
    width: 2em;
    background-color: currentColor;
    top: 0.5em;
  }
}

.u-full-height {
  height: 100% !important;
}

.u-font-inherit {
  font-family: inherit;
}

.u-no-wrap {
  white-space: nowrap;
}

.u-ml__2 {
  margin-left: 2rem;
}

.u-mr__1 {
  margin-right: 1rem;
}

.u-link {
  color: #29abe2;
  text-decoration: underline;
}

.u-input-error {
  font-size: 10px;
  color: var(--ion-color-danger);
  position: relative;
  top: -1rem;
}

.u-padding-vertical-sm {
  padding: 8px 0;
}

.d-inline-block {
  display: inline-block;
}