@import "../../theme/app-theme";

.footer {
  background-color: $footer-grey;
  padding: 2em 0;
}

.socialIcon {
  width: 2em;
  margin-right: 8px;
  object-fit: contain;
}

.transparent div:first-child {
  background: transparent;
}

.link {
  margin-bottom: 6px;
  margin-top: 0;
  font-family: "Avenir Next Medium";

  a:hover {
    text-decoration: underline;
  }
}

.button {
  flex: 1;
}